import React, { useState } from "react";
import BaseModal from "../../BaseModal";
import styles from "./ExtendTrial.module.css";
import Confirm from "../Confirm";
import Spinner from "../../Spinner";

type IExtend = {
    email?: string;
    days: number;
};

interface IExtendTrial {
    isOpen: boolean;
    isLoadingExtend: boolean;
    onClose?: () => void;
    onExtendConfirm?: () => void;
    data: IExtend;
    setDataToExtend: (data: IExtend) => void;
  }

const ExtendTrial: React.FC<IExtendTrial> = (
    props
): JSX.Element => {
    const {
        isOpen,
        isLoadingExtend,
        onClose,
        onExtendConfirm,
        data,
        setDataToExtend,
    } = props;

    const [openConfirmExtend, setOpenConfirmExtend] = useState<boolean>(false);

    // const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);

    return (
        <BaseModal isOpen={isOpen} onClose={onClose} size="small">
            {openConfirmExtend && (
                <Confirm
                    isOpen={openConfirmExtend}
                    size="tiny"
                    text="Are you sure you want to extend trial?"
                    onConfirm={
                        isLoadingExtend
                            ? undefined
                            : () => {
                                onExtendConfirm && onExtendConfirm();
                            }
                    }
                    onDecline={
                        isLoadingExtend ? undefined : () => setOpenConfirmExtend(false)
                    }
                    onClose={() => setOpenConfirmExtend(false)}
                >
                    {isLoadingExtend && (
                        <div>
                            <Spinner text="Extending Trial..." />
                        </div>
                    )}
                </Confirm>
            )}
            <div className={`mx-20 mb-20`}>
                <div className={`${styles.title} mb-2`}>Extend Trial</div>
                <div
                    className={`${styles.desc}`}
                >{`Extend Trial`}</div>

                <div className={`w-full flex justify-between items-center my-14`}>
                    <div>
                        <h4 className={`${styles.label} pb-2`}>email</h4>
                        <input
                            value={data.email}
                            className={`${styles.emailInput} px-3`}
                            onChange={(e) => {
                                setDataToExtend({
                                    ...data,
                                    email: e.target.value
                                });
                            }}
                            type={'string'}
                        />
                    </div>

                    <div>
                        <h4 className={`${styles.label} pb-2`}>days</h4>
                        <input
                            value={data.days}
                            className={`${styles.daysInput} px-3`}
                            onChange={(e) => {
                                setDataToExtend({
                                    ...data,
                                    days: e.target.valueAsNumber
                                });
                            }}
                            type={'number'}
                        />
                    </div>
                </div>
                <div className={`w-full flex justify-center items-center`}>
                    <button
                        onClick={() => {
                            setOpenConfirmExtend(true);
                        }}
                        className={`${styles.blueBtn} mr-5`}
                    >
                        Extend
                    </button>
                    <button
                        onClick={() => {
                            setOpenConfirmExtend(true);
                        }}
                        className={`${styles.blueBtn} mr-5`}
                    >
                        Extend
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};

export default ExtendTrial;
import { debounce } from "lodash";
import { useEffect, useMemo } from "react";
import { FaCheckCircle, FaSearch, FaSpinner, FaTrashAlt } from "react-icons/fa";
import { Dropdown } from "semantic-ui-react";

type FiltersProps = {
  setToggleSearch(): void;
  active: boolean;
  setActive: () => void;
  deleted: boolean;
  setDeleted: () => void;
  deletedCount: number;
  filteredSelection: string;
  serviceFilter: string;
  setFilteredSelection: (val: string) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setServiceFilter: (val: string) => void;
  isLoading: boolean;
};

const Filters = (props: FiltersProps) => {
  const onSearch = useMemo(
    () => debounce(() => props.setToggleSearch(), 700),
    [props.setToggleSearch]
  );

  useEffect(() => {
    return () => {
      onSearch.cancel();
    };
  }, []);

  return (
    <div className="flex justify-between">
      <div className="flex">
        <button
          className={`btnFilter mr-2 ${props.active ? "text-hubGreen" : ""}`}
          onClick={props.setActive}
        >
          <FaCheckCircle size={12} className="mr-1" />
          Active
        </button>
        <button
          className={`btnFilter ${props.deleted ? "text-hubRed" : ""}`}
          onClick={props.setDeleted}
        >
          <FaTrashAlt size={12} className="mr-1" />
          Deleted ({props.deletedCount})
        </button>
      </div>
      <div className="flex">
        <div className="flex border border-hubGrayLt">
          <input
            className="outline-none px-2"
            type="text"
            value={props.serviceFilter}
            placeholder="Find admin"
            onChange={(e) => {
              if (props.currentPage !== 1) {
                props.setCurrentPage(1);
              }
              props.setServiceFilter(e.target.value);
              onSearch();
            }}
          />

          {/* <FaSpinner
            className={`${
              props.isLoading ? "visible mt-4 mr-2 animate-spin" : "invisible"
            }  `}
          /> */}

          <div className="flex border-l border-hubGrayLt p-1">
            <div className="flex justify-center items-center px-2">
              <Dropdown
                value={props.filteredSelection}
                options={[
                  { text: "Service", value: "service" },
                  { text: "Email", value: "email" },
                ]}
                onChange={(e, { value }) => {
                  if (props.currentPage !== 1) {
                    props.setCurrentPage(1);
                  }

                  props.setFilteredSelection(String(value));
                  onSearch();
                }}
              />
            </div>
            <div className="bg-hubGreen text-white flex justify-center items-center px-2.5">
              <FaSearch />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;

import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { subscriptionsApi } from "../../../../api/subscriptions";
import Pagination from "../../../../components/Pagination";

interface ITable {
  active: boolean;
  header: JSX.Element;
  content: JSX.Element;
  page: number;
  changePage: (page: number) => void;
  orderBy:
    | "name"
    | "email"
    | "service"
    | "subscription"
    | "end date"
    | "status";
  order: "desc" | "asc";
  searchBy: string;
  search: string;
  toggleFetch: boolean;
  getData(params: any): void;
  setLoading: (params: boolean) => void;
}

const Table: React.FC<ITable> = (props): JSX.Element => {
  const {
    active,
    header,
    content,
    page,
    changePage,
    orderBy,
    order,
    searchBy,
    search,
    toggleFetch,
    getData,
    setLoading,
  } = props;

  const [numPages, setNumPages] = useState<number>(0);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSubsData = async () => {
      setLoading(true);
      setFetchLoading(true);
      let response: any = [];
      if (active) {
        response = await subscriptionsApi.getSubscription({
          page,
          orderBy,
          order,
          searchBy,
          search,
        });
      } else {
        response = await subscriptionsApi.getSoftSubscription({
          page,
          orderBy,
          order,
          searchBy,
          search,
        });
      }

      console.log("response sub ", response);
      setLoading(false);
      setFetchLoading(false);
      setNumPages(response.total_pages);
      getData(response.data);
    };

    fetchSubsData();
  }, [toggleFetch, active]);

  return (
    <>
      <table
        className={`table-auto w-full ${
          fetchLoading ? "w-full flex flex-col justify-center" : ""
        }`}
      >
        {header}
        {fetchLoading ? (
          <div className={`my-10 flex flex-col justify-center items-center `}>
            <FaSpinner className={`animate-spin text-xl`} />{" "}
            <span className={`text-xl`}>Loading...</span>{" "}
          </div>
        ) : (
          content
        )}
      </table>
      {numPages > 1 && !fetchLoading && (
        <div className="flex gap-2">
          <Pagination
            currentPage={page}
            lastPage={numPages}
            onPageChange={changePage}
          />
        </div>
      )}
    </>
  );
};

export default Table;

import { axiosQip as axios } from "../services/axios";
// comment

export type User = {
  center_deleted_at: string | null;
  center_id: string;
  center_name: string;
  email: string;
  full_name: string;
  subscription: {
    center_id: string;
    center_name: string;
    center_deleted_at: string | null;
    product_name: string;
    status: string;
    trial_remaining_days: string;
    created_at: string;
    subscription_deleted_at: string | null;
    current_period_end: string | null;
    interval: string | null;
    cancel_at_period_end: boolean | null;
  };
  type: string;
  user_deleted_at: string | null;
  user_id: string;
};

export type Center = {
  center_id: User["subscription"]["center_id"];
  center_name: User["subscription"]["center_name"];
  cancel_at_period_end?: User["subscription"]["cancel_at_period_end"];
  product_name?: string;
  subscription: User["subscription"];
  user_deleted_at: User["user_deleted_at"];
  center_deleted_at: User["subscription"]["center_deleted_at"];
  quantity?: number;
  price_id?: string | null;
  current_period_end?: string | null;
  trial_remaining_days?: string | null;
  subscription_id?: string | null;
  interval?: string | null;
  current_period_start?: string | null;
  status?: string | null;
  customer_id?: string | null;
  created_at?: string | null;
};

export type Admin = {
  user_id: User["user_id"];
  email: User["email"];
  type: User["type"];
  full_name: User["full_name"];
  centers: Center[];
};

export async function get() {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(`/api/centers/admin/users?order=asc&orderBy=name&page=1`, config);

  let admins: Admin[] = [];
  let prev_id = 0;
  let tempCenters: Center[] = [];
  let tempAdmin: Admin = {} as Admin;
  let adminsSoft: Admin[] = [];
  let prev_idSoft = 0;
  let tempCentersSoft: Center[] = [];
  let tempAdminSoft: Admin = {} as Admin;
  let paying = 0;
  let trial = 0;
  let payingSoft = 0;
  let trialSoft = 0;
  let softCount = 0;
  let rawActive: User[] = [];
  let rawSoft: User[] = [];

  const sortedAdmins = (response.data as User[]).sort((a, b) => {
    return Number(a.user_id) - Number(b.user_id);
  });

  sortedAdmins.forEach((sub) => {
    if (sub.subscription.product_name === "trial") {
      if (sub.center_deleted_at === null) {
        rawActive = [...rawActive, sub];
      } else {
        rawSoft = [...rawSoft, sub];
      }
    } else {
      if (sub.subscription.subscription_deleted_at === null) {
        rawActive = [...rawActive, sub];
      } else {
        rawSoft = [...rawSoft, sub];
      }
    }
  });

  rawActive.forEach((admin, key) => {
    if (Number(prev_id) === Number(admin.user_id) || Number(prev_id) === 0) {
      tempCenters.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdmin = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCenters,
      };

      if (Number(prev_id) === 0) {
        if (admin.subscription.product_name !== "trial") {
          if (
            admin.subscription.status !== "trial" &&
            admin.subscription.status !== "trialing"
          ) {
            paying = paying + 1;
          } else {
            trial = trial + 1;
          }
        } else {
          trial = trial + 1;
        }
      }

      if (key === rawActive.length - 1) {
        admins.push(tempAdmin);
      }
    } else {
      admins.push(tempAdmin);
      tempCenters = [];
      tempAdmin = {} as Admin;

      if (admin.subscription.product_name !== "trial") {
        if (
          admin.subscription.status !== "trial" &&
          admin.subscription.status !== "trialing"
        ) {
          paying = paying + 1;
        } else {
          trial = trial + 1;
        }
      } else {
        trial = trial + 1;
      }

      tempCenters.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdmin = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCenters,
      };

      if (key === rawActive.length - 1) {
        admins.push(tempAdmin);
      }
    }
    prev_id = Number(admin.user_id);
  });

  rawSoft.forEach((admin, key) => {
    if (
      Number(prev_idSoft) === Number(admin.user_id) ||
      Number(prev_idSoft) === 0
    ) {
      tempCentersSoft.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdminSoft = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCentersSoft,
      };

      if (Number(prev_idSoft) === 0) {
        if (admin.subscription.product_name !== "trial") {
          if (
            admin.subscription.status !== "trial" &&
            admin.subscription.status !== "trialing"
          ) {
            payingSoft = payingSoft + 1;
          } else {
            trialSoft = trialSoft + 1;
          }
        } else {
          trialSoft = trialSoft + 1;
        }
      }

      if (key === rawSoft.length - 1) {
        adminsSoft.push(tempAdminSoft);
        softCount = softCount + tempAdminSoft.centers.length;
      }
    } else {
      adminsSoft.push(tempAdminSoft);
      softCount = softCount + tempAdminSoft.centers.length;
      tempCentersSoft = [];
      tempAdminSoft = {} as Admin;

      if (admin.subscription.product_name !== "trial") {
        if (
          admin.subscription.status !== "trial" &&
          admin.subscription.status !== "trialing"
        ) {
          payingSoft = payingSoft + 1;
        } else {
          trialSoft = trialSoft + 1;
        }
      } else {
        trialSoft = trialSoft + 1;
      }

      tempCentersSoft.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdminSoft = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCentersSoft,
      };

      if (key === rawSoft.length - 1) {
        adminsSoft.push(tempAdminSoft);
        softCount = softCount + tempAdminSoft.centers.length;
      }
    }
    prev_idSoft = Number(admin.user_id);
  });

  return {
    admins: admins.reverse(),
    adminsSoft: adminsSoft.reverse(),
    softDeletedCount: softCount,
    totalSoft: adminsSoft.length,
    payingSoft,
    trialSoft,
    total: admins.length,
    paying: paying,
    trial: trial,
  };
}

export async function getSubSummary() {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(`/api/subscriber-summary`, config);

  return {
    total_subscribers: response.data.total_subscribers,
    total_paying_subscribers: response.data.total_paying_subscribers,
    total_trial_subscribers: response.data.total_trial_subscribers,
  };
}

export const usersApi = {
  get,
  getSubSummary,
};

import {
  FaCalendarAlt,
  FaEdit,
  FaEye,
  FaReply,
  FaTimesCircle,
  FaTrashAlt,
} from "react-icons/fa";
import { useHistory } from "react-router";
import { Admin, Center } from "../../../../api/users";
import styles from "./CogOptions.module.css";
import redirectToQip from "../../../../helpers/redirectToQip";
import redirectToPortal from "../../../../helpers/redirectToPortal";
import redirectToHub from "../../../../helpers/redirectToHub";
import { fetchCenterUsers } from "../../../../api/request";
import { useState } from "react";
import moment from "moment";
import { auditAccess } from "../../../../helpers/auditService";
import BaseModal from "../../../../components/BaseModal";

type ActionDeleteOrRestore = (data: {
  userId: string;
  centerId: string;
  isTrial: boolean;
}) => void;
type ActionCancel = (data: { userId: string; centerId: string }) => void;

type ActionUpdate = (data: {
  product?: string;
  interval: string;
  userId: string;
  centerId: string;
  priceId?: string | null;
  endDate: string;
  subscriptionId?: string | null;
  quantity?: number;
  startDate?: string;
}) => void;

type ActionExtend = (data: { email?: string; days: number }) => void;

type CogOptionsProps = {
  service: Admin;
  center: Center;
  setToDelete: ActionDeleteOrRestore;
  setToCancel: ActionCancel;
  setToRestore: ActionDeleteOrRestore;
  setToUpdate: ActionUpdate;
  setToEdit: ActionUpdate;
  setToExtend: ActionExtend;
  active: boolean;
};
const CogOptions = ({
  service,
  center,
  setToDelete,
  setToCancel,
  setToRestore,
  setToUpdate,
  setToExtend,
  setToEdit,
  active,
}: CogOptionsProps) => {
  const history = useHistory();

  const [redirectingQip, setRedirectingQip] = useState(false);
  const [redirectingPortal, setRedirectingPortal] = useState(false);
  const [redirectingHub, setRedirectingHub] = useState(false);
  const [openExtendModal, setOpenExtendModal] = useState(false);

  const hasQIP =
    center.product_name === "trial" ||
    center.product_name?.toLowerCase().includes("qip");

  const hasPortal =
    center.product_name === "trial" ||
    center.product_name?.toLowerCase().includes("portal");

  const onRedirectToQip = async () => {
    try {
      setRedirectingQip(true);
      const response = await fetchCenterUsers(Number(center.center_id));
      const centerUser = response.filter(
        (u) => Number(u.id) === Number(service.user_id)
      )[0];

      console.log("response red", response);

      redirectToQip(
        Number(centerUser.id),
        Number(center.center_id),
        centerUser.stripe_id || "empty"
      );
    } catch (error) {
      console.error(error);
    } finally {
      setRedirectingQip(false);
    }
  };

  const onRedirectToPortal = async () => {
    try {
      setRedirectingPortal(true);
      const response = await fetchCenterUsers(Number(center.center_id));
      const centerUser = response.filter(
        (u) => Number(u.id) === Number(service.user_id)
      )[0];
      redirectToPortal(centerUser);
    } catch (error) {
      console.error(error);
    } finally {
      setRedirectingPortal(false);
    }
  };

  const onRedirectToHub = async () => {
    try {
      setRedirectingHub(true);
      const response = await fetchCenterUsers(Number(center.center_id));
      const centerUser = response.filter(
        (u) => Number(u.id) === Number(service.user_id)
      )[0];
      redirectToHub(centerUser);
    } catch (error) {
      console.error(error);
    } finally {
      setRedirectingHub(false);
    }
  };

  // const extendTrialModal = async () => {
  //   setOpenExtendModal(true);
  // };
  const userType = JSON.parse(localStorage.getItem("authUser")!).user_type;

  return (
    <>
      {active ? (
        <div className={styles.container}>
          <button onClick={() => onRedirectToHub()}>
            {!redirectingHub ? (
              <>
                <FaEye className="text-hubGray" />
                View user's HUB
              </>
            ) : (
              <span className={`${styles.loading} pl-7`}>Please wait</span>
            )}
          </button>
          {hasQIP && (
            <button
              disabled={!auditAccess(center)}
              onClick={() => {
                if (!redirectingQip && auditAccess(center)) {
                  onRedirectToQip();
                }
              }}
            >
              {!redirectingQip ? (
                <>
                  <FaEye className="text-hubGray" />
                  View user's QIP
                </>
              ) : (
                <span className={`${styles.loading} pl-7`}>Please wait</span>
              )}
            </button>
          )}
          {hasPortal && (
            <button
              disabled={!auditAccess(center)}
              onClick={() => {
                if (!redirectingPortal && auditAccess(center)) {
                  onRedirectToPortal();
                }
              }}
            >
              {!redirectingPortal ? (
                <>
                  <FaEye className="text-hubGray" />
                  View user's Portal
                </>
              ) : (
                <span className={`${styles.loading} pl-7`}>Please wait</span>
              )}
            </button>
          )}
          <button
            onClick={() =>
              history.push("/app-usage", {
                user_id: service.user_id,
              })
            }
          >
            <FaCalendarAlt className="text-hubGray" />
            View usage logs
          </button>
          {center.center_name !== "trial" && (
            <button
              className="disabled:opacity-40 disabled:cursor-not-allowed"
              // disabled={!!center.cancel_at_period_end}
              disabled={
                center.product_name === "trial" || !!center.cancel_at_period_end
              }
              onClick={() =>
                setToCancel({
                  userId: service.user_id,
                  centerId: center.center_id,
                })
              }
            >
              <FaTimesCircle className="text-hubGray" />
              Cancel subscription
            </button>
          )}
          {/* <button 
            onClick={() => setToExtend({
              email: undefined,
              days: 0,
            })
          }>
            <FaEye className="text-hubGray" />
            Extend Trial
          </button> */}
          {/* <button 
            onClick={() => setToUpdate({
              email: undefined,
              days: 0,
            })
          }>
            <FaEye className="text-hubGray" />
            Update Subscription
          </button> */}
          <button
            className="disabled:opacity-40 disabled:cursor-not-allowed"
            disabled={
              !center.cancel_at_period_end && center.product_name !== "trial"
            }
            onClick={() =>
              setToDelete({
                userId: service.user_id,
                centerId: center.center_id,
                isTrial: center.product_name === "trial",
              })
            }
          >
            <FaTrashAlt className="text-hubGray" />
            Delete subscription
          </button>
          {center.product_name === "trial" && (
            <button
              className="disabled:opacity-40 disabled:cursor-not-allowed"
              onClick={() => {
                setToUpdate({
                  product: center.product_name,
                  interval: String(center.interval),
                  userId: service.user_id,
                  centerId: center.center_id,
                  priceId: center.price_id,
                  endDate: moment(
                    center.current_period_end || center.trial_remaining_days,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD hh:mm:ss"),
                  subscriptionId: center.subscription_id,
                  quantity: center.quantity,
                });
              }}
            >
              <FaEdit className={`text-hubGray`} /> Update Trial
            </button>
          )}
          {userType.toLowerCase() === "super_admin" && (
            <button
              className="disabled:opacity-40 disabled:cursor-not-allowed"
              onClick={() => {
                setToEdit({
                  product: center.product_name,
                  interval: String(center.interval),
                  userId: service.user_id,
                  centerId: center.center_id,
                  priceId: center.price_id,
                  endDate: moment(
                    center.current_period_end || center.trial_remaining_days,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD hh:mm:ss"),
                  startDate: moment(
                    center.current_period_start,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD hh:mm:ss"),
                  subscriptionId: center.subscription_id,
                  quantity: center.quantity,
                });
              }}
            >
              <FaEdit className={`text-hubGray`} /> Edit Subscription
            </button>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <button
            onClick={() =>
              setToRestore({
                userId: service.user_id,
                centerId: center.center_id,
                isTrial: center.product_name === "trial",
              })
            }
          >
            <FaReply className="text-hubGray" />
            Restore subscription
          </button>
          <button
            onClick={() =>
              setToDelete({
                userId: service.user_id,
                centerId: center.center_id,
                isTrial: center.product_name === "trial",
              })
            }
          >
            <FaTimesCircle className="text-hubGray" />
            Permanently delete
          </button>
        </div>
      )}
    </>
  );
};

export default CogOptions;

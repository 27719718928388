import React, { useEffect, useState } from "react";
import BaseModal from "../../BaseModal";
import styles from "./UpdateSubscription.module.css";
import Confirm from "../Confirm";
import Spinner from "../../Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { pricing } from "../../../pages/Subscriptionsv2/constants/pricing";

type IUpdate = {
  product?: string;
  interval: string;
  userId: string;
  centerId: string;
  priceId?: string | null;
  endDate: string;
  subscriptionId?: string | null;
  quantity?: number;
};
interface IUpdateSubscription {
  isManual: boolean;
  isOpen: boolean;
  isLoadingUpdate: boolean;
  onClose?: () => void;
  onUpdateConfirm?: () => void;
  onCancelSubNow?: () => void;
  onCancelTrial?: () => void;
  data: IUpdate;
  setDataToUpdate: (data: IUpdate) => void;
  onCancelEndOfBillingCycle?: () => void;
  setOpenConfirmUpdate: (data: boolean) => void;
  openConfirmUpdate: boolean;
}

const subOptions = [
  { key: "trial", text: "Trial", value: "trial" },
  {
    key: "Holistic QIP",
    text: "Qip",
    value: "Holistic QIP",
  },
  {
    key: "Holistic QIP + Mentoring",
    text: "QIP+Mentoring",
    value: "Holistic QIP + Mentoring",
  },
  {
    key: "Holistic Management Portal",
    text: "HMC Portal",
    value: "Holistic Management Portal",
  },
  {
    key: "Holistic Management Portal + Mentoring",
    text: "HMC Portal +Mentoring",
    value: "Holistic Management Portal + Mentoring",
  },
  {
    key: "Holistic QIP & Holistic Management Portal",
    text: "QIP & HMC Portal",
    value: "Holistic QIP & Holistic Management Portal",
  },
  {
    key: "Holistic QIP & Management Portal + Mentoring",
    text: "QIP & HMC Portal +Mentoring",
    value: "Holistic QIP & Management Portal + Mentoring",
  },
];

const UpdateSubscription: React.FC<IUpdateSubscription> = (
  props
): JSX.Element => {
  const {
    isManual,
    isOpen,
    isLoadingUpdate,
    onClose,
    onUpdateConfirm,
    onCancelSubNow,
    onCancelTrial,
    data,
    setDataToUpdate,
    onCancelEndOfBillingCycle,
    openConfirmUpdate,
    setOpenConfirmUpdate,
  } = props;
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    if (data.endDate) {
      setStartDate(new Date(data.endDate));
    }
  }, [data.endDate]);

  const [isValidDate, setIsValidDate] = useState<boolean>(true);
  useEffect(() => {
    setIsValidDate(true);
  }, [data.userId]);

  const [openConfirmCancelTrial, setOpenConfirmCancelTrial] =
    useState<boolean>(false);

  const [openConfirmCancelNotTrial, setOpenConfirmCancelNotTrial] =
    useState<boolean>(false);

  const isTrial = data.product === "trial";

  const onSubDateChange = (date: any) => {
    const today = moment().format("YYYY-MM-DD");
    const endDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    const momentEndDate = moment(date);

    if (endDate >= today && momentEndDate.isValid()) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }

    setStartDate(date);
    setDataToUpdate({
      ...data,
      endDate: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm:ss"),
    });
  };
  const onSubscriptionChange = (subscription: any) => {
    setDataToUpdate({
      ...data,
      priceId: subscription,
    });
  };
  const selectedPriceId = pricing().filter(
    (p) => p.interval === "year" && !p.withTrial
  );
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} size="small">
      {openConfirmUpdate && (
        <Confirm
          isOpen={openConfirmUpdate}
          size="tiny"
          text="Are you sure you want to update subscription?"
          onConfirm={
            isLoadingUpdate
              ? undefined
              : () => {
                  //   setDataToUpdate({
                  //     ...data,
                  //     endDate: moment(updatedEndDate, "YYYY-MM-DD").format(
                  //       "YYYY-MM-DD hh:mm:ss"
                  //     ),
                  //   });
                  onUpdateConfirm && onUpdateConfirm();
                }
          }
          onDecline={
            isLoadingUpdate ? undefined : () => setOpenConfirmUpdate(false)
          }
          onClose={() => setOpenConfirmUpdate(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Updating subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {openConfirmCancelTrial && (
        <Confirm
          isOpen={openConfirmCancelTrial}
          size="tiny"
          text="Are you sure you want to cancel subscription?"
          onConfirm={isLoadingUpdate ? undefined : onCancelTrial}
          onDecline={
            isLoadingUpdate ? undefined : () => setOpenConfirmCancelTrial(false)
          }
          onClose={() => setOpenConfirmCancelTrial(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Canceling subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {openConfirmCancelNotTrial && (
        <Confirm
          isOpen={openConfirmCancelNotTrial}
          size="tiny"
          text="When should subscription be cancelled?"
          confirmText="Cancel Now"
          declineText="End of billing cycle"
          onConfirm={isLoadingUpdate ? undefined : onCancelSubNow}
          onDecline={isLoadingUpdate ? undefined : onCancelEndOfBillingCycle}
          onClose={() => setOpenConfirmCancelNotTrial(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Updating subscription..." />
            </div>
          )}
        </Confirm>
      )}
      <div className={`mx-20 mb-20`}>
        <div className={`${styles.title} mb-2`}>Update subscription</div>
        <div
          className={`${styles.desc}`}
        >{`Update, cancel and delete trials & subscriptions`}</div>

        <div
          className={`w-full flex justify-between items-center my-14`}
          id="subscription-update-form"
        >
          {/* <div> */}
          {/* <div>
            <h4 className={`${styles.label} pb-2`}>Current subscription</h4>
            <Dropdown
              className={`${styles.dropdown} `}
              disabled={isTrial || !isManual}
              selection
              defaultValue={data.product}
              options={[
                ...subOptions.filter((sub) => sub.value === data.product),
                ...subOptions.filter((sub) => sub.value !== data.product),
              ]}
              onChange={(e, val) => {
                const selectedPriceId = pricing().filter(
                  (p) => p.interval === data.interval && p.product === val.value
                );

                if (!isEmpty(selectedPriceId)) {
                  setDataToUpdate({
                    ...data,
                    priceId: selectedPriceId[0].price_id,
                  });
                }
              }}
            />
          </div> */}

          <div className="flex flex-col items-center justify-center w-full">
            <h4 className={`${styles.label} pb-4`}>
              Update Subscription End Date
            </h4>
            <DatePicker
              dateFormat={"dd-MM-yyyy"}
              selected={startDate}
              className={`${styles.dateInput} px-3 w-3/5`}
              onChange={(date: any) => onSubDateChange(date)}
              minDate={new Date()}
              todayButton="Today"
            />
            {/* <h4 className={`${styles.label} pb-4`}>Update Subscription</h4>
            <select onChange={(e) => onSubscriptionChange(e.target.value)}>
              {selectedPriceId.map((items) => {
                return <option value={items.price_id}>{items.product}</option>;
              })}
            </select> */}

            {/* <input
                            disabled={!isManual}
                            value={updatedEndDate}
                            className={`${styles.dateInput} px-3 w-3/5`}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={onSubDateChange}
                            type={`date`}
                        /> */}
            {!isValidDate && (
              <div className={`${styles.error}`} style={{ color: "red" }}>
                Please enter a valid date
              </div>
            )}
          </div>
        </div>
        {isManual && (
          <div className={`w-full flex justify-center items-center`}>
            <button
              onClick={() => {
                setOpenConfirmUpdate(true);
              }}
              className={`${styles.blueBtn} mr-5`}
              disabled={!isValidDate}
            >
              Update Subscription
            </button>
            <button
              onClick={() => {
                isTrial
                  ? setOpenConfirmCancelTrial(true)
                  : setOpenConfirmCancelNotTrial(true);
              }}
              className={`${styles.redBtn} ml-5`}
            >
              Cancel Subscription
            </button>
          </div>
        )}
        {!isManual && (
          <div className={`w-full flex flex-col justify-center items-center`}>
            <div
              className={`${styles.isNotManualDesc}`}
            >{`This subscription is paid via Stripe. Updates should be made directly by the subscriber, or in the Stripe. You can cancel the subscription below, which would take affect at the end of the user’s current billing cycle.`}</div>
            <button onClick={onClose} className={`${styles.redBtn} mt-10`}>
              Cancel Subscription
            </button>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default UpdateSubscription;

import React, { useEffect, useState } from "react";
import BaseModal from "../../BaseModal";
import styles from "./UpdateSubscription.module.css";
import Confirm from "../Confirm";
import Spinner from "../../Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { pricing } from "../../../pages/Subscriptionsv2/constants/pricing";

type IUpdate = {
  product?: string;
  interval: string;
  userId: string;
  centerId: string;
  priceId?: string | null;
  endDate: string;
  subscriptionId?: string | null;
  quantity?: number;
  startDate?: string;
};
interface IUpdateSubscription {
  isManual?: boolean;
  isOpen: boolean;
  isLoadingUpdate: boolean;
  onClose?: () => void;
  onUpdateConfirm?: () => void;
  onCancelSubNow?: () => void;
  onCancelTrial?: () => void;
  data: IUpdate;
  setDataToEdit: (data: IUpdate) => void;
  onCancelEndOfBillingCycle?: () => void;
  setOpenConfirmUpdate: (data: boolean) => void;
  openConfirmUpdate: boolean;
}

const EditSubscription: React.FC<IUpdateSubscription> = (
  props
): JSX.Element => {
  const {
    isManual,
    isOpen,
    isLoadingUpdate,
    onClose,
    onUpdateConfirm,
    onCancelSubNow,
    onCancelTrial,
    data,
    setDataToEdit,
    onCancelEndOfBillingCycle,
    openConfirmUpdate,
    setOpenConfirmUpdate,
  } = props;
  // console.log(data.startDate);
  const [startDate, setStartDate] = useState(new Date());
  var date = moment(data.startDate);
  useEffect(() => {
    if (data.startDate && date.isValid()) {
      setStartDate(new Date(data.startDate));
    } else {
      setStartDate(new Date());
    }
  }, [data.startDate]);
  // const [isValidDate, setIsValidDate] = useState<boolean>(true);
  // useEffect(() => {
  //   setIsValidDate(true);
  // }, [data.userId]);

  const [openConfirmCancelTrial, setOpenConfirmCancelTrial] =
    useState<boolean>(false);

  const [openConfirmCancelNotTrial, setOpenConfirmCancelNotTrial] =
    useState<boolean>(false);
  const [initialSub, setInitialSub] = useState(data.priceId);
  const [finalSub, setFinalSub] = useState(data.priceId);

  const onSubDateChange = (date: any) => {
    const today = moment().format("YYYY-MM-DD");
    const endDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    const momentEndDate = moment(date);

    // if (endDate >= today && momentEndDate.isValid()) {
    //   setIsValidDate(true);
    // } else {
    //   setIsValidDate(false);
    // }

    // setStartDate(date);
    setDataToEdit({
      ...data,
      startDate: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm:ss"),
    });
    // console.log("datatoeditafteredit", data);
  };
  const [isValidPriceId, setValidPriceId] = useState(true);
  const onSubscriptionChange = (subscription: any) => {
    setValidPriceId(true);
    setFinalSub(subscription);
    setDataToEdit({
      ...data,
      priceId: subscription,
    });
  };
  const selectedPriceId = pricing().filter(
    (p) => p.interval === "year" && !p.withTrial
  );
  console.log("first", data);
  const checkPriceValue = () => {
    const today = moment().format("YYYY-MM-DD");
    if (data.priceId === null) {
      setValidPriceId(false);
      setOpenConfirmUpdate(false);
    } else if (data.startDate === "Invalid date") {
      setDataToEdit({
        ...data,
        startDate: moment(today, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm:ss"),
      });
      setOpenConfirmUpdate(true);
    } else {
      setOpenConfirmUpdate(true);
    }
  };
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} size="small">
      {openConfirmUpdate && (
        <Confirm
          isOpen={openConfirmUpdate}
          size="tiny"
          text="Are you sure you want to edit subscription?"
          onConfirm={
            isLoadingUpdate
              ? undefined
              : () => {
                  //   setDataToUpdate({
                  //     ...data,
                  //     endDate: moment(updatedEndDate, "YYYY-MM-DD").format(
                  //       "YYYY-MM-DD hh:mm:ss"
                  //     ),
                  //   });
                  onUpdateConfirm && onUpdateConfirm();
                }
          }
          onDecline={
            isLoadingUpdate ? undefined : () => setOpenConfirmUpdate(false)
          }
          onClose={() => setOpenConfirmUpdate(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Editing subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {openConfirmCancelTrial && (
        <Confirm
          isOpen={openConfirmCancelTrial}
          size="tiny"
          text="Are you sure you want to cancel subscription?"
          onConfirm={isLoadingUpdate ? undefined : onCancelTrial}
          onDecline={
            isLoadingUpdate ? undefined : () => setOpenConfirmCancelTrial(false)
          }
          onClose={() => setOpenConfirmCancelTrial(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Canceling subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {openConfirmCancelNotTrial && (
        <Confirm
          isOpen={openConfirmCancelNotTrial}
          size="tiny"
          text="When should subscription be cancelled?"
          confirmText="Cancel Now"
          declineText="End of billing cycle"
          onConfirm={isLoadingUpdate ? undefined : onCancelSubNow}
          onDecline={isLoadingUpdate ? undefined : onCancelEndOfBillingCycle}
          onClose={() => setOpenConfirmCancelNotTrial(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Updating subscription..." />
            </div>
          )}
        </Confirm>
      )}
      <div className={`mx-20 mb-20`}>
        <div
          className={`w-full flex justify-between items-center mt-14 mb-10`}
          id="subscription-update-form"
        >
          <div className="flex flex-col items-center justify-center w-full">
            <h4 className={`${styles.title} mb-3`}>
              Update Subscription Start Date
            </h4>
            <DatePicker
              dateFormat={"dd-MM-yyyy"}
              selected={startDate}
              className={`${styles.dateInput} px-3 w-3/5 rounded-none border-gray-300`}
              onChange={(date: any) => onSubDateChange(date)}
              maxDate={new Date()}
              todayButton="Today"
            />
            <h4 className={`${styles.title} mb-3 mt-5`}>Edit Subscription</h4>
            <select
              onChange={(e) => onSubscriptionChange(e.target.value)}
              defaultValue={"default"}
              className="w-3/5 p-4 border border-gray-300 border-solid capitalize"
            >
              <option className="capitalize" value={"default"} hidden>
                {data.product}
              </option>
              {selectedPriceId.map((items) => {
                return <option value={items.price_id}>{items.product}</option>;
              })}
            </select>
            {!isValidPriceId && (
              <div className="w-3/5 pt-2" style={{ color: "red" }}>
                User is already in Trial. Please select a new subscription.
              </div>
            )}
          </div>
        </div>
        <div className={`w-full flex justify-center items-center`}>
          <button
            onClick={() => {
              checkPriceValue();
            }}
            className={`${styles.blueBtn} px-3 w-52`}
            disabled={!isValidPriceId}
          >
            Edit Subscription
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditSubscription;

import moment from "moment";
import { Center } from "../api/users";

export const auditAccess = (center: Center) => {
  const hasSub = center.customer_id ? true : false;

  const canStillAccessTrial =
    center.product_name === "trial" &&
    moment(new Date()).diff(moment(center.created_at, "DD-MM-YYYY"), "days") <=
      30;

  const failedPayment = center.customer_id
    ? center.status === "incomplete_expired" ||
      center.status === "incomplete" ||
      center.status === "past_due" ||
      center.status === "unpaid"
    : false;

  const getpFDayDiff = () => {
    if (failedPayment) {
      const currentPeriodStart = center.current_period_start
        ? center.current_period_start
        : -1;

      if (currentPeriodStart !== -1) {
        const date = moment.unix(
          Math.floor(moment(currentPeriodStart, "DD-MM-YYYY").valueOf() / 1000)
        );

        const dateNow = moment.unix(Math.floor(Date.now() / 1000));

        const diff = Math.ceil(moment.duration(date.diff(dateNow)).asDays());

        return diff;
      } else {
        return 1000;
      }
    } else {
      return 1000;
    }
  };

  const canStillAccessSub =
    (failedPayment && getpFDayDiff() >= -7) ||
    (hasSub && (center.status === "active" || center.status === "trialing"));

  if (hasSub) {
    return canStillAccessSub;
  } else {
    return canStillAccessTrial;
  }
};
